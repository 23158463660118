@font-face {
  font-family: "Inter";
  src: url("Inter.ttf");
}

@font-face {
  font-family: "Jacarta";
  src: url("PlusJakartaSans.ttf");
}

@font-face {
  font-family: "Roboto Mono";
  src: url("RobotoMono-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("RobotoMono-Regular.ttf");
  font-weight: 400;
}
