@import-normalize;

html {
  margin: 0;
  box-sizing: border-box;
  cursor: none;
  overflow-x: hidden;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body,
#root {
  scroll-behavior: smooth;
  transition: color, background-color 0.3s linear;
}

video::-webkit-media-controls,
video::-moz-media-controls,
video::-o-media-controls,
video::-ms-media-controls {
  display: none !important;
}

video {
  pointer-events: none;
}

#root>header,
section {
  opacity: 0;
  animation: .7s ease-out fade forwards;
  max-width: 100vw;
}

.on-load {
  animation-delay: 3.25s;
}

/* .cky-consent-bar, .cky-preference-wrapper {
    background-color: initial !important;
    background: rgba(255, 255, 255, 0.7) !important;
    backdrop-filter: blur(12px) !important;
    border: 0px !important;
} */

.projectPage {
  padding: 140px 0 0;
  display: flex;
  flex-direction: column;
  gap: 0;

  @media (max-width: 1280px) {
    gap: 24px;
    padding: 90px 0 0;
  }
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-acc-grpcntr.ot-acc-txt,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-acc-txt .ot-subgrp-tgl .ot-switch.ot-toggle {
  background-color: #05070D !important;
}

#ot-sdk-btn-floating .ot-floating-button__front,
#onetrust-pc-sdk .ot-tgl input:checked+.ot-switch .ot-switch-nob,
#ot-sdk-btn-floating .ot-floating-button__back {
  background-color: rgba(25, 83, 255, 0.85) !important;
}

#onetrust-consent-sdk #onetrust-banner-sdk {
  box-shadow: 5px 15px 20px 0px rgba(0, 0, 0, 0.25);
  background: rgba(5, 7, 13, .5) !important;
  backdrop-filter: blur(4px);
  border: none !important;
  outline: none !important;
}

#onetrust-consent-sdk #onetrust-pc-btn-handler,
#onetrust-consent-sdk #onetrust-pc-btn-handler.cookie-setting-link {
  background-color: transparent !important;
}

#accept-recommended-btn-handler,
.privacy-notice-link {
  position: relative !important;
  z-index: 10 !important;
}

#onetrust-consent-sdk #onetrust-pc-btn-handler,
#onetrust-consent-sdk #onetrust-pc-btn-handler.cookie-setting-link {
  border: 0px !important;
  outline: none !important;
}

.grecaptcha-badge {
  right: -100% !important;
}

@media screen and (max-width: 768px) {
  *::-webkit-scrollbar {
    display: none;
  }
}